import React, { useState } from 'react';
import { Container, useBreakpointValue, Flex } from '@chakra-ui/react';

import { Header } from './Header';
import { NavBar } from './NavBar';
import { HEADER_HEIGHT, NAVBAR_WIDTH } from '../../constants/components';
import { PageLayoutProps } from '../../types/components';

const PageLayout: React.FC<PageLayoutProps> = ({
  children,
  containerProps
}) => {
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const isMobile = useBreakpointValue({ base: true, desktopNav: false });

  const toggleSidebar = () => {
    setSidebarOpen((prevState) => !prevState);
  };

  const handleCloseSideBar = () => {
    if (isMobile) {
      setSidebarOpen(false);
    }
  };

  return (
    <>
      <Header onToggleSidebar={toggleSidebar} />
      <Container
        height={`calc(100vh - ${HEADER_HEIGHT})`}
        display="flex"
        flexDir="column"
        justifyContent="center"
        alignItems="center"
        mt={HEADER_HEIGHT}
        {...containerProps}
      >
        <NavBar isOpen={isSidebarOpen} onClose={handleCloseSideBar} />
        <Flex
          width={
            isSidebarOpen && !isMobile ? `calc(100% - ${NAVBAR_WIDTH})` : '100%'
          }
          height="100%"
          ml={isSidebarOpen && !isMobile ? NAVBAR_WIDTH : 0}
          transition="margin-left 0.3s ease-in-out"
          overflow="auto"
          justifyContent="center"
          alignItems="flex-start"
        >
          {children}
        </Flex>
      </Container>
    </>
  );
};

export { PageLayout };
