import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo
} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLazyQuery } from '@apollo/client';
import { PropTypes } from 'prop-types';

import groupQueries from '../services/queries/Group';
import { saveOnSession } from '../helpers/session';
import { UserSettingsTypes, hideGroupInsights } from '../helpers/user';

import LoadingSpinner from '../components/LoadingSpinner';
import { INSIGHTS_VISIBILITY } from '../constants/insights';
import { SUBSCRIPTION_TYPES } from '../helpers/user';
import { useTranslation } from 'react-i18next';
import {
  CLINICIAN_ADMIN_PERMISSION,
  CLINICIAN_PERMISSION,
  CONTENT_CREATOR_PERMISSION,
  USER_ROLES_PROP
} from '../constants/user';

const UserDataContext = createContext({});

function useUserData() {
  return useContext(UserDataContext);
}

function UserDataProvider({ children }) {
  const [tries, setTries] = useState(0);
  const [isLoading, setIsLoading] = useState();

  const [userData, setUserData] = useState();
  const [isInsightsAvailable, setIsInsightsAvailable] = useState(false);

  const {
    isAuthenticated,
    isLoading: auth0Loading,
    user,
    loginWithRedirect,
    getAccessTokenSilently,
    logout
  } = useAuth0();

  const { i18n } = useTranslation();

  const [
    getUserDetails,
    { loading: userDetailsLoading, data: userDetailsData }
  ] = useLazyQuery(groupQueries.GET_USER_DETAILS, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      console.log('Error on getting User Data', error);
      getUserGroup();
    }
  });

  const isPlus = useMemo(
    () => (userData ? userData.subscriptionType !== 'Basic' : false),
    [userData]
  );

  const type = useMemo(
    () => (userData ? SUBSCRIPTION_TYPES[userData.subscriptionType] : 'Free'),
    [userData]
  );

  const handleLogout = () => {
    logout();
  };

  const setTokenOnSession = async () => {
    try {
      const token = await getAccessTokenSilently();
      saveOnSession('CheckingIn.token', token);
    } catch (e) {
      console.error('Error on saving session token', e);
    }
  };

  const getUserGroup = async () => {
    if (tries > 5) {
      handleLogout();
      return;
    }

    setTries(tries + 1);
    if (!isLoading) {
      await setTokenOnSession();

      await getUserDetails();
    }
  };

  useEffect(() => {
    const dataUser = userDetailsData?.user;
    if (dataUser && user) {
      const insightsVisibility = dataUser?.groupInsightsFilter;
      if (insightsVisibility) {
        if (insightsVisibility === INSIGHTS_VISIBILITY.VISIBLE_TO_ALL) {
          setIsInsightsAvailable(true);
        } else if (insightsVisibility === INSIGHTS_VISIBILITY.NOT_VISIBLE) {
          setIsInsightsAvailable(false);
        } else if (insightsVisibility === INSIGHTS_VISIBILITY.ADMINS_ONLY) {
          const isAdmin = dataUser?.isAdmin || false;
          setIsInsightsAvailable(isAdmin);
        }
      } else {
        setIsInsightsAvailable(true);
      }

      const isContentCreator =
        user?.[USER_ROLES_PROP]?.includes(CONTENT_CREATOR_PERMISSION) || false;
      const isClinician =
        user?.[USER_ROLES_PROP]?.includes(CLINICIAN_PERMISSION) || false;
      const isClinicianAdmin =
        user?.[USER_ROLES_PROP]?.includes(CLINICIAN_ADMIN_PERMISSION) || false;

      const insightsFilter = dataUser.groupInsightsFilter;
      user.hideGroupInsightsMenu = hideGroupInsights(
        insightsFilter,
        dataUser.isAdmin
      );

      user.avatar = user.picture;
      user.geoip = user['https://web.checkingin.co/geoip'];
      user.group = dataUser.groupName;
      user.isEnterprise = !!dataUser?.groupId;
      user.isCompanyAdmin = dataUser.isAdmin;
      dataUser.isContentCreator = isContentCreator;
      dataUser.isClinician = isClinician || isClinicianAdmin;
      dataUser.isClinicianAdmin = isClinicianAdmin;

      dataUser.timeZone = user?.geoip?.time_zone;
      dataUser.name = dataUser.name || user.name;

      setUserData({
        ...user,
        ...dataUser,
        userSettings: userDetailsData?.userSetting,
        emailFull: user.email
      });
    }
  }, [userDetailsData, user]);

  useEffect(() => {
    if (userDetailsData?.userSetting) {
      const userLanguage = userDetailsData?.userSetting
        .find(
          (setting) =>
            setting.type === UserSettingsTypes.PREFERENCES &&
            setting.platform === 'web'
        )
        ?.meta.find((item) => item.name === 'language')?.value;

      if (!userLanguage) {
        i18n.changeLanguage('en');
      }

      if (userLanguage !== i18n.language) {
        i18n.changeLanguage(userLanguage);
      }
    }
  }, [userDetailsData?.userSetting]);

  useEffect(() => {
    if (!user && !auth0Loading) {
      loginWithRedirect({ action: 'signup' });
    }

    if (user && !auth0Loading && isAuthenticated) {
      getUserGroup();
    }
  }, [user, auth0Loading]);

  useEffect(() => {
    setIsLoading(auth0Loading && userDetailsLoading);
  }, [auth0Loading, userDetailsLoading]);

  return (
    <UserDataContext.Provider
      value={{
        userData,
        isPlus,
        logout: handleLogout,
        type,
        isInsightsAvailable,
        refetch: getUserDetails
      }}
    >
      {(isLoading || !userData) && <LoadingSpinner wrapper />}
      {!isLoading && userData && children}
    </UserDataContext.Provider>
  );
}

UserDataProvider.propTypes = {
  children: PropTypes.any.isRequired
};

export { UserDataProvider, useUserData };
